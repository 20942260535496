<template>
  <div class="shop">
    <van-sticky :offset-top="0">
      <van-dropdown-menu active-color="#617bfa">
        <van-dropdown-item title="小区"
          ref="xqitem">
          <div>
            <van-field v-model="listfrom.OrganName"
              is-link
              readonly
              label="社区"
              placeholder="请选择社区"
              @click="showSq = true" />
            <van-popup v-model="showSq"
              round
              position="bottom">
              <van-cascader v-model="cascaderValue"
                title="请选择社区"
                :options="orgonList"
                @close="showSq = false"
                :field-names="{
                text: 'OName',
                value: 'OCode',
                children: 'Children',
              }"
                @finish="changeSq" />
            </van-popup>
            <van-field v-model="listfrom.title"
              label="小区"
              placeholder="请选择小区"
              @click="xiaoquListPicker = true"
              readonly="readonly"
              clickable />
            <van-popup v-model="xiaoquListPicker"
              position="bottom">
              <van-picker show-toolbar
                :columns="xiaoqu"
                value-key="Title"
                @confirm="xiaoquListQr"
                @cancel="xiaoquListPicker = false" />
            </van-popup>
            <div style="width: 95%; margin: 10px">
              <van-button round
                type="info"
                size="small"
                style="text-center: right;font-size:16px;"
                block
                color="#617bfa"
                @click="onSearch()">搜索</van-button>
            </div>
          </div>
        </van-dropdown-item>
        <van-dropdown-item title="住户"
          ref="item">
          <van-field v-model="dataform.OrganName"
            is-link
            readonly
            label="社区"
            placeholder="请选择社区"
            @click="showXq = true" />
          <van-popup v-model="showXq"
            round
            position="bottom">
            <van-cascader v-model="cascaderValue"
              title="请选择社区"
              :options="orgonList"
              @close="showXq = false"
              :field-names="{
                text: 'OName',
                value: 'OCode',
                children: 'Children',
              }"
              @finish="onFinish" />
          </van-popup>
          <van-field v-model="dataform.Title"
            label="小区"
            placeholder="请选择小区"
            @click="xiaoquPicker = true"
            readonly="readonly"
            clickable />
          <van-popup v-model="xiaoquPicker"
            position="bottom">
            <van-picker show-toolbar
              :columns="xiaoqu"
              value-key="Title"
              @confirm="xiaoquQr"
              @cancel="xiaoquPicker = false" />
          </van-popup>
          <van-field v-model="dataform.LdTitle"
            label="楼栋"
            placeholder="楼栋"
            @click="loudongPicker = true"
            readonly="readonly"
            clickable />
          <van-popup v-model="loudongPicker"
            position="bottom">
            <van-picker show-toolbar
              :columns="loudong"
              value-key="Title"
              @confirm="loudongQr"
              @cancel="loudongPicker = false" />
          </van-popup>
          <van-field v-model="dataform.dyTitle"
            label="单元"
            placeholder="单元"
            @click="danyuanPicker = true"
            readonly="readonly"
            clickable />
          <van-popup v-model="danyuanPicker"
            position="bottom">
            <van-picker show-toolbar
              :columns="danyuanList"
              value-key="Title"
              @confirm="danyuanQr"
              @cancel="danyuanPicker = false" />
          </van-popup>
          <van-field v-model="dataform.Name"
            label="姓名"
            placeholder="住户姓名"
            clickable />
          <van-field v-model="dataform.MobileTel"
            label="电话"
            placeholder="电话"
            clickable />
          <van-field v-model="dataform.IdNumber"
            label="身份证号"
            placeholder="身份证号"
            clickable />
          <div style="width: 95%; margin: 10px">
            <van-button round
              type="info"
              size="small"
              style="text-center: right;font-size:16px;"
              block
              color="#617bfa"
              @click="zHChaxun()">搜索</van-button>
          </div>
        </van-dropdown-item>
      </van-dropdown-menu>
    </van-sticky>
    <!-- tab栏 -->
    <div class="information"
      v-if="!zhList">
      <!-- 全部 -->
      <van-list :loading="loading"
        :finished="finished"
        offset="0"
        @load="onLoad">
        <div class="center"
          v-for="item in xqlist"
          :key="item.index"
          style="font-size: 17px;border:1px solid rgb(222 206 206);">
          <!-- finished-text="没有更多了" -->
          <!-- <router-link :to="'/article/detail/' + item.RIId"> -->
          <div class="shopTitle van-ellipsis"
            style="padding-bottom: 0">
            {{ item.Title }}
          </div>
          <div class="Content"
            style="padding: 8px 10px"
            @click="showPopup(item.RId)">
            <div style="display: inline-block">
              <!-- <span style="color: #333" class="van-multi-ellipsis--l3"
                ><van-icon name="location" />{{ item.Address }}</span
              > -->
              <div style="font-size: 17px; color: #333; margin: 5px 0">
                所在社区：{{ item.OrganName }}
              </div>
              <span class="centertime"
                style="color: #333; font-size: 17px">
                <van-icon name="manager" />{{ item.Linkmen }}
                <van-icon name="phone"
                  style="margin-left: 5px" />{{
                  item.Linkway
                }}
              </span>
            </div>
            <!-- <div style="display: inline-grid; width: 29%">
              <van-button
                round
                type="info"
                size="mini"
                style="text-center: right"
                @click="updataXq(item)"
                >修改</van-button
              >
            </div> -->
          </div>
          <!-- </router-link> -->
        </div>
      </van-list>
      <!-- <div style="">
        <div
          style="
            width: 95%;
            bottom: 0;
            display: grid;
            position: fixed;
            padding: 10px;
          "
        >
          <van-button
            round
            type="info"
            size="small"
            style="text-center: right"
            block
            @click="addXq()"
            >新增</van-button
          >
        </div>
      </div> -->
      <van-popup v-model="show"
        closeable
        round
        position="bottom"
        :style="{ height: '70%' }">
        <div style="margin: 40px 0px; float: left; text-align: center; width: 100%">
          <div style="font-size: 15px">楼栋列表</div>
          <!-- @click="danyuan(item.BId)" -->
          <div v-for="item in ldlist"
            :key="item.index"
            style="
              width: 27%;
              float: left;
              margin: 10px;
              line-height: 30px;
              background-color: #a0ace6;
              border-radius: 5px;
            ">
            <div @click="gotoUrl(item)"
              style="color: black">
              <!-- <span class="van-ellipsis">{{ item }}</span> -->
              <span class="van-ellipsis">{{ item.Title }}</span><br />
              <!-- <span class="van-ellipsis">{{ item.GName }}</span> -->
              <span class="van-ellipsis">总户数：{{ item.InFamilyNum }}</span>
            </div>
          </div>
          <van-empty v-if="wushuju"
            description="暂无数据" />
        </div>
      </van-popup>
      <!-- <van-popup v-model:show="show" position="top" :style="{ height: '30%' }" /> -->
      <!-- 没有更多数据了 -->
      <div v-if="hidden"
        class="hidden">───── 没有更多数据了 ─────</div>
    </div>
    <!-- 住户列表 -->
    <div class="information"
      v-if="zhList">
      <!-- 全部 -->
      <van-list :loading="loading"
        :finished="finished"
        offset="0"
        @load="onLoadZh">
        <div class="center"
          v-for="item in zhlistXq"
          :key="item.index"
          style="border:1px solid rgb(222 206 206);">
          <div class="shopTitle van-ellipsis"
            style="padding-bottom: 0">
            {{ item.Title }}
          </div>
          <div class="Content"
            style="padding: 8px 10px">
            <div style="display: inline-block"
              @click="zhuhuList('/wangge/danyuan/jiating/' + item.BRId + '/'+ 0 +'/'+ undefined  + '/' + undefined + '/'+ undefined +'/'+ undefined  + '/' + undefined )">
              <span class="centertime"
                style="color: #333; font-size: 17px">
                <van-icon name="manager" />{{ item.Name }}
                <van-icon name="phone"
                  style="margin-left: 5px" />{{
                  item.MobileTel
                }}
              </span>
              <div style="font-size: 17px; color: #333; margin: 5px 0">
                身份证号：{{ item.IdNumber }}
              </div>
            </div>
          </div>
        </div>
      </van-list>
      <div v-if="hidden"
        class="hidden">───── 没有更多数据了 ─────</div>
    </div>
  </div>
</template>
<script>
import wx from "weixin-js-sdk";
import {
  GetRegionPage,
  GetBuildPage,
  WeGetRegionList,
  WeGetBuildList,
  WeGetBuildUnitList,
  GetResidentPage,
  GetRegionList,
  GetBuildList,
  GetBuildUnitList
} from "@/api/wangge";
import { getwgToken, setwgToken } from "@/utils/auth";
import { GetOrganList, GetOrganTree } from "@/api/Organ";
import Vue from "vue";
import { List } from "vant";
Vue.use(List);
export default {
  data () {
    return {
      showXq: false,
      showSq: false,
      showSs: false,
      xiaoquListPicker: false,
      activeNames: [],
      cheack: true,
      riKind: 0, //tab栏
      xqlist: [], //资讯列表
      listfrom: {
        page: 0,
        limit: 10,
        organCode: "",
        status: 1,
        title: ""
      }, //查询传参
      search: "", //搜索内容
      hidden: true, //没有更多数据了
      loading: false, //加载
      finished: false, //数据完全加载结束为 true
      total: undefined, //数据条数
      show: false,
      wushuju: false,
      ldfrom: {
        page: 1,
        limit: 10,
        rId: "",
      },
      ldlist: {},
      itemmark: "",
      dataform: {
        page: 1,
        limit: 10,
      },
      cascaderValue: "",
      xiaoqu: [],
      xiaoquPicker: false, //小区(组)
      loudongPicker: false, //楼栋
      loudong: [],
      danyuanPicker: false, //单元
      danyuanList: [],
      orgonList: [],
      zhList: false,
      zhlistXq: [],
    };
  },
  methods: {
    gotoUrl: function (row) {
      console.log(row);
      this.BId = JSON.stringify(row.BId);
      this.GId = JSON.stringify(row.GId);
      this.RId = JSON.stringify(row.RId);
      this.$router.push("/wangge/danyuan/" + this.BId + "/" + this.GId + "/" + this.RId);
    },
    //跳转到公共页面
    // gotoUrl(path) {
    //   console.log(path);
    //   if (window.__wxjs_environment === "miniprogram") {
    //     window.wx.miniProgram.navigateTo({
    //       url: "../public/goto?path=" + path,
    //     });
    //   } else {
    //     this.$router.push(path);
    //   }
    // },
    // 获取小区(组)分页列表
    getList () {
      var newlist = []
      //   this.listfrom.riKind = this.riKind;
      // this.listfrom.title = this.search;
      this.listfrom.title = this.listfrom.title;
      this.listfrom.organCode = this.listfrom.organCode;
      // this.listfrom.accToken = getwgToken()
      this.listfrom.accToken = getwgToken();
      GetRegionPage(this.listfrom)
        .then((res) => {
          let rows = res.data.data; //请求返回当页的列表
          // 加载状态结束
          this.loading = false;

          if (res.data.code == 0) {
            if (rows == null || rows.length === 0) {
              // 加载结束
              this.finished = true;
              return;
            }
            // 将新数据与老数据进行合并
            this.xqlist = this.xqlist.concat(rows);

            //如果列表数据条数>=总条数，不再触发滚动加载
            if (this.xqlist.length >= res.data.count) {
              this.finished = true;
            }
          }
        })
        .catch(() => { });
    },
    showPopup (RId) {
      console.log(RId);
      this.show = true;
      this.ldList(RId);
    },
    showPopupSs () {
      this.showSs = true;
    },
    //   danyuan(BId){
    //       console.log(BId);
    //   },
    // 跳转职位详情
    danyuan (row) {
      this.itemmark = JSON.stringify(row);
      this.$router.push({
        name: "danyuan",
        query: { BId: this.itemmark },
      });
    },
    // 获取楼栋列表
    ldList (RId) {
      //   this.listfrom.riKind = this.riKind;
      var rId = RId;
      var accToken = getwgToken();
      GetBuildPage({ accToken: accToken, rId: rId, page: 1, status: 1, limit: 999 })
        .then((res) => {
          if (res.data.code == 0) {
            if (res.data.count == 0) {
              // this.hidden = true;
              this.finished = true;
              this.wushuju = true;
              this.ldlist = {};
            } else {
              this.ldlist = res.data.data;
              this.total = res.data.count;
              this.finished = true;
              this.wushuju = false;
              // this.hidden = false;
              for (var i = 0; i < this.list.length; i++) {
                var lditem = this.list[i].IssTime;
                lditem = this.dataFormat(lditem);
              }
            }
          } else {
            Toast.loading({
              message: "数据异常",
              forbidClick: true,
            });
          }
        })
        .catch(() => { });
    },
    onClose () {
      this.setData({ show: false });
    },
    // 下拉刷新
    onLoad () {
      console.log(123);
      this.listfrom.page = this.listfrom.page + 1;
      this.getList();
    },
    onLoadZh () {
      console.log(123);
      this.dataform.page = this.dataform.page + 1;
      this.zhlistXq = [];
      this.zHChaxun();
    },
    // tan点击切换事件
    onClick (e) {
      this.riKind = e;
      this.listfrom.page = 1;
      this.getList();
    },
    // 搜索
    onSearch () {
      this.xqlist = []
      this.$refs.xqitem.toggle();
      this.zhlistXq = {}
      // this.xqlist={}
      this.zhList = false
      this.listfrom.page = 1;
      this.getList();
    },
    // 时间格式化
    dataFormat (value) {
      var year = value.substr(0, 4);
      var month = value.substr(5, 2);
      var day = value.substr(8, 2);
      var hour = value.substr(11, 2);
      var min = value.substr(14, 2);
      var second = value.substr(17, 2);
      return (
        year + "-" + month + "-" + day + " " + hour + ":" + min + ":" + second
      );
    },
    //编辑
    updataXq: function (item) {
      this.itemmark = JSON.stringify(item.RId);
      var path = "/wangge/xqbianji/" + this.itemmark;
      if (window.__wxjs_environment === "miniprogram") {
        window.wx.miniProgram.navigateTo({
          url: "../public/goto?path=" + path,
        });
      } else {
        this.$router.push(path);
      }
    },
    // 新增
    addXq: function () {
      var path = "/wangge/xqbianji/0";
      if (window.__wxjs_environment === "miniprogram") {
        window.wx.miniProgram.navigateTo({
          url: "../public/goto?path=" + path,
        });
      } else {
        this.$router.push(path);
      }
    },
    // 社区组织
    getOrgonTree () {
      GetOrganTree({ accToken: getwgToken() }).then((res) => {
        console.log(res);
        this.orgonList = res.data.data;
      });
    },
    changeSq ({ selectedOptions }) {
      console.log(selectedOptions);
      this.showSq = false;
      this.listfrom = {
        page: 1,
        limit: 10,
        isShowChild: false,
        organCode: "",
        title: "",
      };
      this.fieldValue = selectedOptions.map((option) => option.OName).join("/");
      this.OCode = selectedOptions.map((option) => option.OCode).join("/");
      this.listfrom.OrganName = this.fieldValue;
      this.listfrom.organCode = selectedOptions[1].OCode;
      console.log(this.fieldValue);
      this.getXqTree(this.listfrom.organCode);


    },
    onFinish ({ selectedOptions }) {
      this.showXq = false;
      this.dataform = {
        page: 1,
        limit: 10,
        BId: undefined,
        BUId: undefined,
        RId: undefined,
        IdNumber: "",
        LdTitle: "",
        MobileTel: "",
        Name: "",
        Title: "",
        dyTitle: "",
      };
      this.fieldValue = selectedOptions.map((option) => option.OName).join("/");
      this.OCode = selectedOptions.map((option) => option.OCode).join("/");
      this.dataform.OrganName = this.fieldValue;
      this.dataform.OrganCode = selectedOptions[1].OCode;
      console.log(this.fieldValue);
      this.getXqTree(this.dataform.OrganCode);
    },
    // 小区(组)
    getXqTree (OrganCode) {
      GetRegionList({ accToken: getwgToken(), organCode: OrganCode, useType: 2 }).then(
        (res) => {
          console.log(res.data);
          this.xiaoqu = res.data.data;
        }
      );
    },
    //小区(组)列表确认
    xiaoquListQr (val) {
      console.log(val);
      // this.listfrom.RId = val.RId;
      this.listfrom.title = val.Title;
      this.xiaoquListPicker = false;
    },
    //小区(组)确认
    xiaoquQr (val) {
      console.log(val);
      this.dataform.RId = val.RId;
      this.dataform.Title = val.Title;
      this.xiaoquPicker = false;
      this.dataform.BId = undefined;
      this.dataform.LdTitle = "";
      this.dataform.BUId = undefined;
      this.dataform.dyTitle = "";
      this.dataform.Name = "";
      this.dataform.IdNumber = "";
      this.dataform.MobileTel = "";
      this.getLdTree(this.dataform.RId);
    },
    // 楼栋
    getLdTree (RId) {
      GetBuildList({ rId: RId, useType: 2, accToken: getwgToken() }).then((res) => {
        console.log(res.data);
        this.loudong = res.data.data;
      });
    },
    //楼栋确认
    loudongQr (val) {
      console.log(val);
      this.dataform.BId = val.BId;
      this.dataform.LdTitle = val.Title;
      this.loudongPicker = false;
      this.dataform.BUId = undefined;
      this.dataform.dyTitle = "";
      this.dataform.Name = "";
      this.dataform.IdNumber = "";
      this.dataform.MobileTel = "";
      this.getDyTree(this.dataform.BId);
    },
    // 单元
    getDyTree (BId) {
      GetBuildUnitList({ bId: BId, useType: 2, accToken: getwgToken() }).then((res) => {
        console.log(res.data);
        this.danyuanList = res.data.data;
      });
    },
    //单元确认
    danyuanQr (val) {
      console.log(val);
      this.dataform.BUId = val.BUId;
      this.dataform.dyTitle = val.Title;
      this.dataform.Name = "";
      this.dataform.IdNumber = "";
      this.dataform.MobileTel = "";
      this.danyuanPicker = false;
    },
    zHChaxun: function () {
      this.$refs.item.toggle();
      this.zhList = true;
      this.showSs = false;
      console.log(this.dataform);
      this.dataform.accToken = getwgToken();
      this.dataform.Status = 1;
      this.zhlistXq = [];
      GetResidentPage(this.dataform)
        .then((res) => {
          // 加载状态结束
          this.loading = false;
          if (res.data.code == 0) {
            this.showSs = false;
            this.total = res.data.count;
            if (res.data.count == 0) {
              this.finished = true;
              this.zhlistXq = [];
              this.showSs = false;
            } else {
              if (res.data.data.length == 0) {
                this.finished = true;
                this.showSs = false;
              } else {
                var newlist = this.zhlistXq;
                this.zhlistXq = newlist.concat(res.data.data);
                this.showSs = false;
              }
            }
          }
        })
        .catch(() => { });
    },
    // zhuhuList: function (row) {
    //   console.log(row.BRId);
    //   this.itemmark = JSON.stringify(row.BRId);
    //   this.$router.push({
    //     name: "jiating",
    //     query: { BRId: this.itemmark },
    //   });
    // },
    zhuhuList (path) {
      if (window.__wxjs_environment === "miniprogram") {
        window.wx.miniProgram.navigateTo({
          url: "../public/goto?path=" + path,
        });
      } else {
        this.$router.push(path);
      }
    },
  },
  mounted () {
    if (this.$route.query["wgToken"]) {
      // this.$store.dispatch('user/setopenid', { openid: this.$route.query['openid'] })
      setwgToken(this.$route.query["wgToken"]);
    }
    // this.getList();
    console.log(this.$route);
    this.getOrgonTree();
  },
};
</script>
<style>
.shopTitle {
  color: #333;
  padding: 10px;
}
.shop .information .center {
  border-bottom: none;
  padding: 0px;
  border-radius: 5px;
}
.shop .center {
  width: 92%;
  margin: 10px auto;
  /* background: rgb(246, 243, 243); */
  background: white;
}
.hidden {
  text-align: center;
  font-size: 12px;
  color: #ccc;
  margin: 10px 0px 25px 0px;
}
</style>
<style scoped>
/deep/.shop .center {
  width: 92%;
  margin: 10px auto;
  background: white !important;
}
/deep/.van-dropdown-menu__title {
  font-size: 17px !important;
}
</style>